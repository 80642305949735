import React, { useState, useEffect } from "react";

const PaymentForm = () => {
  const [isloading, setIsLoading] = useState(false);
  //let BASE_URL='https://stagingapi.lifti.io';
  let BASE_URL='https://liveapi.lifti.io';
  //let BASE_URL='http://localhost:3001';
  
  const [formData, setFormData] = useState({
    CURRENCY_CODE: "PKR",
    MERCHANT_ID: "24422",
    MERCHANT_NAME: "LIFTI APP",
    TOKEN: "",
    BASKET_ID: "",
    TXNAMT: "",
    ORDER_DATE: new Date().toISOString(),
    SUCCESS_URL: `${BASE_URL}/payfast/callback/success`,
    FAILURE_URL: `${BASE_URL}/payfast/callback/failure`,
    CHECKOUT_URL: `${BASE_URL}/payfast/callback/checkout`,
    CUSTOMER_EMAIL_ADDRESS: "",
    CUSTOMER_MOBILE_NO: "",
    SIGNATURE: "SOME-RANDOM-STRING",
    VERSION: "MERCHANT-CART-0.1",
    TXNDESC: "Item Purchased from Cart",
    PROCCODE: "00",
    TRAN_TYPE: "ECOMM_PURCHASE",
    STORE_ID: "",
    RECURRING_TXN: "",
    ITEMS: [
      { SKU: "LIFI-SKU-01", NAME: "LIFTI Token", PRICE: 150, QTY: 2 },
    ],
  });


  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const _TransactionAmount = urlParams.get("TransactionAmount");
    const _BASKET_ID = urlParams.get("BASKET_ID");
    const TOKEN = urlParams.get("TOKEN");
    const _CUSTOMER_EMAIL_ADDRESS= urlParams.get("CUSTOMER_EMAIL_ADDRESS");
    const _CUSTOMER_MOBILE_NO= urlParams.get("CUSTOMER_MOBILE_NO");

    setFormData((prevState) => ({
        ...prevState,
        TOKEN: TOKEN|| "",
        BASKET_ID: _BASKET_ID|| "",
        TXNAMT:_TransactionAmount || "",
        CUSTOMER_EMAIL_ADDRESS:_CUSTOMER_EMAIL_ADDRESS || "",
        CUSTOMER_MOBILE_NO:_CUSTOMER_MOBILE_NO || ""
      }));

  }, [formData.MERCHANT_ID, formData.BASKET_ID, formData.TXNAMT]);




  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true); // Show loader
  
    setTimeout(() => {
      const form = document.createElement("form");
      form.method = "POST";
      form.action = "https://ipg1.apps.net.pk/Ecommerce/api/Transaction/PostTransaction";
  
      Object.entries(formData).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            Object.entries(item).forEach(([itemKey, itemValue]) => {
              const input = document.createElement("input");
              input.type = "hidden";
              input.name = `ITEMS[${index}][${itemKey}]`;
              input.value = itemValue;
              form.appendChild(input);
            });
          });
        } else {
          const input = document.createElement("input");
          input.type = "hidden";
          input.name = key;
          input.value = value;
          form.appendChild(input);
        }
      });
  
      document.body.appendChild(form);
      form.submit();
      setIsLoading(false); // Hide loader after submission
    }, 2000); // Delay of 2 seconds
  };
  const visibleFields = ["MERCHANT_NAME","TXNAMT", "CUSTOMER_EMAIL_ADDRESS","CUSTOMER_MOBILE_NO"];
  return (
    <div className="container">
      <h2 className="text-center">
        PayFast Payment Redirection
      </h2>
      <form className="form-inline" onSubmit={handleSubmit}>
        {Object.keys(formData).map((key) => {
             const isVisible = visibleFields.includes(key);
          if (key === "ITEMS") return null;
          return (
            isVisible && <div className="mb-3" key={key}>
              <label className="form-label">{key.replace("_", " ")}:</label>
              <input
                type="text"
                className="form-control"
                name={key}
                value={formData[key]}
                readOnly={true}
                onChange={handleInputChange}
              />
            </div>
          );
        })}

        {isloading ? 
        <div className="text-center">
                <h2>Processing your payment...</h2>
                <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
                </div>
            </div>
          :
        <button type="submit" className="w-100 btn btn-primary">
          Submit
        </button>}
      </form>
    </div>
  );
};

export default PaymentForm;
