import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import TransactionResult from "./transactionstatus.js";
import PaymentForm from "./payfastform.js";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Swich from "./swich.js";
const root = ReactDOM.createRoot(document.getElementById("root"));

const AppRoute = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/swich" element={<Swich />} />
        <Route path="/success" element={<TransactionResult />} />
        <Route path="/payfast" element={<PaymentForm />} />
      </Routes>
    </Router>
  );
};
root.render(
  <React.StrictMode>
    <AppRoute />
  </React.StrictMode>
);



reportWebVitals();


